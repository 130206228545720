import styles from "./header.module.scss";
import logo from "./orange-logo.png";
import {fbIcon, hamburgerIcon, instagramIcon, phoneIcon} from "../icons";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import classNames from "classnames/bind";
import useScreenType from "react-screentype-hook";

const navList = [['home', 'Home'], ['about', 'O firmie'], ['offer', 'Oferta'], ['realizations', 'Realizacje'], ['contact', 'Kontakt']];
const cx = classNames.bind(styles);

const clamp = (value) => Math.max(0, value);

const isBetween = (value, floor, ceil) =>
    value >= floor && value <= ceil;


const useScrollspy = (ids, offset= 0) => {
  const [activeId, setActiveId] = useState("");

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;

      const position = ids
          .map((id) => {
            const element = document.getElementById(id);

            if (!element) return { id, top: -1, bottom: -1 };

            const rect = element.getBoundingClientRect();
            const top = clamp(rect.top + scroll - offset);
            const bottom = clamp(rect.bottom + scroll - offset);

            return { id, top, bottom };
          })
          .find(({ top, bottom }) => isBetween(scroll, top, bottom));

      setActiveId(position?.id || "");
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, [ids, offset]);

  return activeId;
};

export const Header = () => {
  const [openNav, setOpenNav] = useState(false);
  const [height, setHeight] = useState(0)
  const ref = useRef(null)
  const screenType = useScreenType({largeDesktop: 1600, desktop: 1023, tablet: 0, mobile: 0});
  const ids = ["home", "about", "contact", "offer", "realizations"];
  const activeId = useScrollspy(ids, 54);


  const toggleNav = () => setOpenNav(!openNav);

  useEffect(() => {
    setHeight(ref.current.clientHeight + 40)
  },[])

  return <header className={cx("header", openNav && 'active')}>
      <div className={styles.logoContainer}><img alt="logo" src={logo}/></div>
      <nav>
        <div style={{maxHeight: screenType.isTablet ? openNav ? height : 0 : '100%'}}  className={styles.wrapper}>
          <div ref={ref} className={styles.navContainer}>
            <ul>
              {navList.map((li, index) => <li key={index} className={li[0] === activeId ? styles.active : ""}><a key={index} onClick={toggleNav} href={`#${li[0]}`}>{li[1]}</a></li>)}
            </ul>
            <div className={styles.socials}>
              <a onClick={toggleNav} target="_blank" rel="noreferrer" href="https://www.facebook.com/people/Topor-Garage/100082839352804/"> {fbIcon} </a>
              <a onClick={toggleNav} target="_blank" rel="noreferrer" href="https://www.instagram.com/topor_garage/"> {instagramIcon} </a>
              <a onClick={toggleNav} href="tel:668 677 181"> {phoneIcon} </a>
            </div>
          </div>
        </div>
        <div onClick={toggleNav} className={cx("hamburger", openNav && 'active')}> {hamburgerIcon} </div>
      </nav>
  </header>
}