import styles from './contact.module.scss';
import bg from './contact-bg.jpg'
import contactData from './contactData.json';
import {Button} from "../../components/Button/Button";
import email from './email.png';
import map from './map.png';
import phone from './phone.png';

const iconsArray = [map, phone, email];

const SingleContact = ({contact, index}) => {
  return <div className={styles.singleContact}>
    <div className={styles.titleDiv}>
      <img src={iconsArray[index]} alt={"icon"}/>
      <h3> {contact.title} </h3></div>
    <div className={styles.descDiv}>
      <p>{contact.desc}</p>
      {contact.desc2 && <p>{contact.desc2}</p>}
    </div>
    <a target="_blank" rel="noreferrer" href={contact.href}><Button>{contact.button}</Button></a>
  </div>
}

export const Contact = () => {
  return <section id="contact" className={styles.contact}>
    <img className={styles.background} src={bg} alt={"background contact"}/>
    <div className="container">
      <h2>kontakt</h2>
      <div className={styles.row}>
        {contactData.contact.map((contact, index) => <SingleContact key={index} index={index} contact={contact}/>)}
      </div>
    </div>
  </section>
}