import styles from './herobanner.module.scss';
import bg from './hero_bg.jpg';
import {Button} from "../../components/Button/Button";
import map from './map.svg';
import mapFill from './map-fill.svg';

export const Herobanner = () => {
  return <section id="home" className={styles.herobanner}>
    <div className={styles.gradientLayer}/>
    <img alt="bg" className={styles.bg} src={bg}/>
    <div className={styles.container}>
      <div className={styles.heroText}>
        <a href="#contact" className={styles.city}>{<img alt="icon" className={styles.map}  src={map}/>}{<img className={styles.fillMap} alt="icon" src={mapFill}/>} Zielona Góra </a>
        <h1>profesjonalna regeneracja</h1>
        <h1>felg samochodowych</h1>
        <a href='#offer'><Button>sprawdź ofertę</Button></a>
      </div>
    </div>
  </section>
}