import styles from './realizations.module.scss';
import {useEffect, useState} from "react";
import {Button} from "../../components/Button/Button";
import {instagramIconBig} from "../../components/icons";

export const Realizations = () => {
  const [array, setArray] = useState([])

  useEffect(() => {
    setArray([]);
    fetch(`https://graph.instagram.com/me?fields=media&access_token=${process.env.REACT_APP_TOKEN}`)
        .then((response) => response.json().then(posts => {
          posts?.media?.data.slice(0, 9).forEach(data => {
            fetch(`https://graph.instagram.com/${data.id}?fields=id,media_type,media_url,thumbnail_url&access_token=${process.env.REACT_APP_TOKEN}`)
                .then((response) => response.json())
                .then((actualData) => setArray(arrayState => [...arrayState, actualData]))
          })
        }))
  }, []);


  return <section id="realizations" className={styles.realizations}>
    <div className={"container"}>
      <h2>ostatnie realizacje</h2>
      <div className={styles.grid}>
        {array?.slice(0, 9).map(post => <a key={post.id} href="https://www.instagram.com/topor_garage/" target="_blank"
                                           rel="noreferrer">
          <div className={styles.imageWrapper}><p> @topor_garage</p> {instagramIconBig}
              <img alt="instagram pic" src={post.media_type !== 'VIDEO' ? post.media_url : post.thumbnail_url}/>
      </div>
        </a>)}
      </div>
      <div className={styles.center}>
        <a href={"https://www.instagram.com/topor_garage"} target="_blank" rel="noreferrer"
           className={styles.buttonA}><Button>Sprawdź nasz instagram</Button></a>
      </div>
    </div>
  </section>
}