import styles from "./offer.module.scss";
import bg from "./offer-bg.jpg";
import offerData from "./offerData.json";

export const Offer = () => {
  return <section id={"offer"} className={styles.offer}>
    <img className={styles.background} src={bg} alt={"background"}/>
    <div className={'container'}>
      <h2>oferta</h2>
      <p className={styles.description}> Naprawa felg w naszym zakładzie wykonywana jest przez kadrę wykwalifikowaną w
        zakresie obróbki skrawaniem, materiałoznawstwa oraz spawalnictwa. Dzięki kompleksowemu wyposażeniu warsztatu
        wszystkie usługi odbywają się w naszym zakładzie.</p>
      <div className={styles.box}>
        {offerData.offer.map((offer, index) => <div key={index} className={index % 2 ? styles.right : ""}>
          <h3> {offer.title}</h3> <p>{offer.desc}</p></div>)}
      </div>
    </div>
  </section>
}