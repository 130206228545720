import styles from './App.module.scss';
import {Herobanner} from "./pages/Herobanner/Herobanner";
import {Header} from "./components/Header/Header";
import {About} from "./pages/About/About";
import {Offer} from "./pages/Offer/Offer";
import {Realizations} from "./pages/Realizations/Realizations";
import {Contact} from "./pages/Contact/Contact";
import {Footer} from "./components/Footer/Footer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {NotFound} from "./pages/NotFound/NotFound";
// import {PriceList} from "./pages/PriceList/PriceList";

const Home = () => (
    <>
      <Header/>
      <Herobanner/>
      <About/>
      <Offer/>
      <Realizations/>
      {/*<PriceList/>*/}
      <Contact/>
      <Footer/>
    </>
)


function App() {
  return (
      <div className={styles.App}>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route path={`/`} element={<Home/>}/>
              <Route path="*" element={<NotFound/>}/>
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
