import styles from "./about.module.scss";
import logo from "./perfect-black-logo.png";


export const About = () => {
  return <section id="about" className={styles.about}>
    <div className={'container'}>
      <h2>o firmie</h2>
      <div className={styles.grid}>
        <div className={styles.info}>
          <p>
            Firma <b>Topor Garage</b> mieści się w Zielonej Górze. Została założona przez <b>Karola Tomporowskiego</b>. Początkowo była to działalność jednoosobowa. Z czasem, dzięki ciężkiej pracy, firma zaczęła się rozwijać i tak z kolejnymi zleceniami i kontraktami pojawiły się w zespole nowe osoby. Dziś obsługujemy klientów z kraju i zza granicy, a nasze realizacje zostały docenione zarówno przez osoby prywatne jak i przez duże firmy. Stawiamy przede wszystkim na jakość.
          </p>
        </div>
        <div className={styles.logoDiv}>
          <img alt="logo" className={styles.logo} src={logo}/>
        </div>
      </div>
    </div>
  </section>
}